import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { getAll, create, del, update } from './../api'
import type { IPlug, IStubType, stubType } from './types'

export const useRestaurantPlugModel = defineStore('restaurantPlug', () => {
  const plugs = ref<IPlug[]>([])
  const stubs = ref<IStubType[]>([
    { id: 0, code: 'FULL', label: 'Сервис полностью' },
    { id: 1, code: 'WITHOUT_TIPS', label: 'Только оплату без чаевых' },
    { id: 2, code: 'PAYMENT', label: 'Только оплату' },
    // { id: 3, code: 'SBP', label: 'Только оплату СБП' }
  ])

  const selectedCount = computed(() => {
    const map = new Map()
    console.log('plugs.value', plugs.value)

    for (const item of plugs.value) {
      map.set(item.id, item.shops.length)
    }
    return map
  })

  // Получение списка всех заглушек
  const getAllPlugs = async () => {
    const result = await getAll()
    if (result.length) {
      plugs.value = result
      return
    }
  }

  const createStub = async () => {
    await create({
      stub_type: "FULL",
      name: `Заглушка ${plugs.value.length + 1}`,
      shops: []
    })

    const result = await getAll()
    plugs.value = result
  }

  // Выбрать все
  const selectAll = (id: number, restIdArr: number[]) => {
    const restIdArrMod = restIdArr.map((id: number) => { return {id, description: ''}})
    plugs.value = plugs.value.map(plug => {
      if (plug.id === id) {
          plug.shops = [...restIdArrMod, ...plug.shops]
      }
      return plug
    })
  }

  // Снять все выделения
  const resetAll = (id: number) => {
    plugs.value = plugs.value.map(plug => {
      if (plug.id === id) {
        plug.shops = []
      }
      return plug
    })
  }

  // Изменение имени
  const setName = (id: number, name: string) => {
    plugs.value = plugs.value.map(plug => {
      if (plug.id === id) {
        plug.name = name
      }
      return plug
    })
  }

  // Выбрать ресторан
  const selectRest = (idPlug: number, id: number) => {
    plugs.value = plugs.value.map(plug => {
      if (plug.id === idPlug) {
        const flag = plug.shops.some(i => i.id === id)
        if (flag) {
          const arr = plug.shops.filter(i => i.id !== id)
          plug.shops = arr
        } else {
          plug.shops.push({ id, description: '' })
        }
      }
      return plug
    })
  }

  // Выбор заглушки
  const selectStub = (id: number, stub: stubType) => {
    plugs.value = plugs.value.map(plug => {
      if (plug.id === id) {
        plug.stub_type = stub
      }
      return plug
    })
  }

  // Комментарий
  const setComment = (idPlug: number, idRest: number, comment: Event) => {
    const target = comment.target as HTMLInputElement

    plugs.value = plugs.value.map(plug => {
      if (plug.id === idPlug) {
        plug.shops = plug.shops.map(shop => {
          if (shop.id === idRest) {
            shop.description = target.value
          }
          return shop
        })
      }
      return plug
    })
  }

  // Удаление заглушки
  const deleteStub = async (id: number) => {
    await del(id)
    plugs.value = plugs.value.filter(plug => plug.id !== id)
  }

  // Обновление списка заглушек
  const updatePlug = async (plug: IPlug) => {
    await update(plug)
    await getAllPlugs()
  }

  return {
    plugs,
    stubs,
    selectedCount,
    getAllPlugs,
    setName,
    selectRest,
    selectAll,
    resetAll,
    createStub,
    selectStub,
    deleteStub,
    updatePlug,
    setComment
  }
})
